<template>
  <v-container class="carrusels fullscreen-div" fluid>
    <v-row class="d-flex flex-column">
      <v-col
        cols="12"
        md="12"
        lg="12"
        sm="12"
        xs="12"
        class="d-flex justify-center"
      >
        <p class="text-center textBenefit">
          <span class="textBenefit">Nuestros</span>
        <span class="textBenefit">programas</span>
        </p>
      </v-col>
    <div class="hidden-sm-and-down">
      <CarrouselWeb />
      </div>
     <div class="hidden-md-and-up">
      <CarrouselMobile />
     </div>
    </v-row>
  </v-container>
</template>

<script>
import CarrouselWeb from './CarrouselWeb.vue';
import CarrouselMobile from './CarrouselMobile.vue';

export default {
  name: 'ProgramsPage',
  components: {
    CarrouselWeb,
    CarrouselMobile,
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";
.fullscreen-div {
  background-color: #F9FAFB !important;
  height: 100%;
  overflow: hidden;
}
.textBenefit {
  font-size: 48px;
  font-weight: 600;
  font-family: $principal-font !important;
  color: $secondary-blue;
}
@media (max-width: 1920px) {
  .textBenefit {
    margin-top: 48px;
  }
  .textBenefit span {
    margin-right: 8px;
  }
}
@media (max-width: 1366px) {
  .textBenefit {
    margin-top: 22px;
  }
  .textBenefit span {
    margin-right: 8px;
  }
}
@media (max-width: 1024px) {
  .textBenefit {
    font-size: 48px;
    margin-top: 35px;
  }
  .textBenefit span {
    margin-right: 12px;
  }
}
@media (max-width: 688px) {
  .textBenefit {
    font-size: 38px;
    margin-top: 52px;
  }
  .textBenefit span {
    margin-right: 8px;
  }
}
@media (max-width: 414px) {
  .textBenefit {
    font-size: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .textBenefit span {
    margin-top: 4px;
  }
}
@media (max-width: 393px) {
  .textBenefit {
    font-size: 32px;
    margin-top: 10px;
  }
  .textBenefit span {
    margin-top: 0px;
  }
}
</style>
