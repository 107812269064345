<template>
  <v-row class="hidden-md-and-up">
    <v-col cols="12" md="12" lg="12" sm="12" xs="12" class="margin-top-md">
      <carousel-3d
        space="240"
        perspective="0"
        @after-slide-change="onAfterSlideChange"
        @before-slide-change="onBeforeSlideChange"
        v-model="selectedCardIndex"
        width="250"
        height="360"
        :controls-visible="false"
        :controls-width="5"
        :controls-height="5"
        :clickable="false"
        class="carousel-container"
      >
        <slide
          v-for="(item, index) in itemsPrograms"
          :index="index"
          :key="item.index"
          :cols="getColumnSize()"
          :class="index === selectedCardIndex ? 'selected' : 'custom-card'"
        >
          <v-card
            width="236"
            height="390"
            :class="index === selectedCardIndex && 'selected'"
          >
            <v-card width="370" height="344" class="inner-card">
              <v-card-text class="d-flex justify-center align-center">
                 <v-row class="d-flex justify-center align-center">
                <v-img
                  :src="
                    require(`../../../assets/newlanding/Programs/${item.icon}.svg`)
                  "
                  :style="`max-width: ${item.maxWidth}; margin-top: ${item.marginTop} !important;`"
                  class="mt-5 transparent-image"
                ></v-img>
                </v-row>
              </v-card-text>
              <v-row class="d-flex justify-center align-center">
                <p class="text-description text-center">
                  {{ item.description }}
                </p>
              </v-row>
            </v-card>
          </v-card>
        </slide>
      </carousel-3d>
    </v-col>
  </v-row>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  name: 'CarrouselMobile',
  components: {
    Carousel3d,
    Slide,
  },
  data() {
    return {
      isMobile: false,
      selectedCardIndex: 0,
      itemsPrograms: [
        {
          icon: 'inkluye',
          maxWidth: '152px',
          marginTop: '47px',
          description:
            'Formamos e insertamos a población vulnerable desde hace más de 10 años para que logren sus metas y sueños a través de trabajo digno.',
        },
        {
          icon: 'green',
          maxWidth: '135px',
          marginTop: '42px',
          description:
            'Programa basado en los principios del Pacto Mundial y los Objetivos de Desarrollo Sostenible (ODS) 2030.',
        },
        {
          icon: 'basta',
          maxWidth: '145px',
          marginTop: '20px',
          description:
            'Brindamos apoyo y ponemos a disposición herramientas y mecanismos que ayudan a sanar episodios de violencia.',
        },
        {
          icon: 'tulado',
          maxWidth: '148px',
          marginTop: '50px',
          description:
            'Programa de Acompañamiento Psicolaboral de la mano de especialistas para ayudar a nuestros/as colaboradores/as a superar episodios de estrés, ansiedad, depresión, etc.',
        },
        {
          icon: 'life',
          maxWidth: '100px',
          marginTop: '44px',
          description:
            'Empoderamos a las mujeres high potentials de la organización y promovemos una cultura de equidad para convertirlas en agentes de cambio.',
        },
        {
          icon: 'ellos',
          maxWidth: '134px',
          marginTop: '25px',
          description:
            'Promovemos espacios de diálogo y educación para hombres líderes de la empresa, y así contribuir al fortalecimiento de una cultura de equidad',
        },
      ],
    };
  },

  methods: {
    getColumnSize() {
      return this.isMobile ? 12 : 4;
    },
    getAspectRatio() {
      return this.isMobile ? 1.5 : 1;
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 767;
    },
    onAfterSlideChange(index) {
      this.selectedCardIndex = index;
    },
    onBeforeSlideChange(index) {
      this.selectedCardIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";

.margin-top-md {
  margin-top: 12px !important;
}
.carousel-container {
  width: 100%;
}
.inner-card {
  margin: 8px;
  margin-left: 8px;
  margin-right: 8px;
  border: none;
}
.custom-card {
  border-radius: 10px;
  border: none;
  background: $principal-white;
  background-color: $step-gray;
  box-shadow: 0 0 10px $step-black-medium;
  .inner-card {
    opacity: 0.7;
    border: none;
    box-shadow: 0 0 0 0px;
  }
  .transparent-image {
    opacity: 0.7;
    filter: blur(5px);
  }
  .text-description {
    font-family: $principal-font!important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    background-color: $step-gray;
    opacity: 0.2;
    text-shadow: 0 0 10px $step-black-dark;
    margin-top: 40px !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
}
.selected {
  background: $background-color-gradient;
  border-radius: 20px;
  border: none;
  .text-description {
    font-family: $principal-font!important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    color: $principal-blue-black;
    margin-top: 40px !important;
    margin-left: 26px !important;
    margin-right: 26px !important;
  }
}
.text-title {
  font-family: $principal-font!important;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
}
.text-description {
  font-family: $principal-font!important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
}
@media (max-width: 688px) {
  .carousel-container {
    text-align: center;
  }
  .margin-top-md {
   margin-top: 62px !important;
}
}
@media (max-width: 414px) {
  .margin-top-md {
   margin-top: 12px !important;
}
}
@media (max-width: 393px) {
  .margin-top-md {
   margin-top: -2px !important;
}
}
</style>
