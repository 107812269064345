<template>
  <div>
    <v-col cols="12" md="12" lg="12" sm="12" xs="12" class="margin-top-large">
      <carousel-3d
        space="350"
        perspective="0"
        @after-slide-change="onAfterSlideChange"
        @before-slide-change="onBeforeSlideChange"
        v-model="selectedCardIndex"
        width="380"
        height="460"
        :controls-visible="true"
        :controls-width="6"
        :controls-height="6"
        :clickable="false"
        class="carousel-container"
      >
        <slide
          v-for="(item, index) in itemsProgramsWeb"
          :index="index"
          :key="item.index"
          :class="
            index === selectedCardIndex ? 'selectedWeb' : 'custom-card-web'
          "
        >
          <v-card
            width="360"
            height="480"
            :class="index === selectedCardIndex && 'selectedWeb'"
          >
            <v-card width="400" height="440" class="inner-card">
              <v-card-text class="d-flex justify-center align-center">
                <v-row class="d-flex justify-center align-center">
                  <v-img
                    :src="
                      require(`../../../assets/newlanding/Programs/${item.icon}.svg`)
                    "
                    :style=" `max-width: ${item.maxWidth}; margin-top: ${item.marginTop} !important;`"
                    class="mb-4 mt-4 transparent-image"
                  ></v-img>
                </v-row>
              </v-card-text>
              <v-row class="d-flex justify-center align-center">
                <p class="text-description text-center">
                  {{ item.description }}
                </p>
              </v-row>
            </v-card>
          </v-card>
        </slide>
      </carousel-3d>
    </v-col>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  name: 'CarrouselWeb',
  components: {
    Carousel3d,
    Slide,
  },
  data() {
    return {
      selectedCardIndex: 0,
      itemsProgramsWeb: [
        {
          icon: 'inkluye',
          maxWidth: '231px',
          marginTop: '82px',
          description:
            'Formamos e insertamos a población vulnerable desde hace más de 10 años para que logren sus metas y sueños a través de trabajo digno.',
        },
        {
          icon: 'green',
          maxWidth: '198px',
          marginTop: '55px',
          description:
            'Programa basado en los principios del Pacto Mundial y los Objetivos de Desarrollo Sostenible (ODS) 2030.',
        },
        {
          icon: 'basta',
          maxWidth: '200px',
          marginTop: '22px',
          description:
            'Brindamos apoyo y ponemos a disposición herramientas y mecanismos que ayudan a sanar episodios de violencia.',
        },
        {
          icon: 'tulado',
          maxWidth: '248px',
          marginTop: '60px',
          description:
            'Programa de Acompañamiento Psicolaboral de la mano de especialistas para ayudar a nuestros/as colaboradores/as a superar episodios de estrés, ansiedad, depresión, etc.',
        },
        {
          icon: 'life',
          maxWidth: '166px',
          marginTop: '55px',
          description:
            'Empoderamos a las mujeres high potentials de la organización y promovemos una cultura de equidad para convertirlas en agentes de cambio.',
        },
        {
          icon: 'ellos',
          maxWidth: '195px',
          marginTop: '25px',
          description:
            'Promovemos espacios de diálogo y educación para hombres líderes de la empresa, y así contribuir al fortalecimiento de una cultura de equidad',
        },
      ],
    };
  },
  methods: {
    onAfterSlideChange(index) {
      this.selectedCardIndex = index;
    },
    onBeforeSlideChange(index) {
      this.selectedCardIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_app.scss";
.carousel-container {
  width: 100%;
}
.inner-card {
  margin: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border: none;
}
.custom-card-web {
  border-radius: 10px;
  border: none;
  background: $principal-white;
  background-color: $step-gray;
  box-shadow: 0 0 10px $step-black-medium;
  .inner-card {
    border: none;
    box-shadow: 0 0 0 0px;
  }
  .transparent-image {
    opacity: 0.7;
    filter: blur(5px);
    margin-top: 32px;
  }
  .text-description {
    filter: blur(4px);
    font-family: $principal-font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    background-color: $step-gray;
    opacity: 0.2;
    text-shadow: 0 0 10px $step-black-dark;
    margin-top: 32px;
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
}
.selectedWeb {
  background: $background-color-gradient;
  border-radius: 20px;
  border: none;
  .text-description {
    font-family: $principal-font !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    color: $principal-blue-black;
    margin-top: 32px !important;
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
}
.text-title {
  font-family: $principal-font!important;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
}
.text-description {
  font-family: $principal-font !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
}
@media (max-width: 1920px) {
  .textBenefit {
    margin-top: 12px;
  }
  .margin-top-large {
  margin-top: 58px;
  }
}
@media (max-width: 1440px) {
  .textBenefit {
    margin-top: 4px;
  }
  .margin-top-large {
  margin-top: -10px;
  }
}
@media (max-width: 1366px) {
  .textBenefit {
    margin-top: 4px;
  }
  .margin-top-large {
  margin-top: -18px;
  }
}
@media (max-width: 1024px) {
  .textBenefit {
    margin-top: 4px;
  }
  .margin-top-large {
  margin-top: -16px;
  }
}
@media (max-width: 688px) {
  .carousel-container {
    text-align: center;
  }
  .textBenefit {
    font-size: 32px;
    margin-bottom: 60px;
    line-height: 110%;
  }
  .carousel-container {
    text-align: center;
  }
}
</style>
